import { EventList } from "@/components/list/event-list";
import { Pagination } from "@/components/pagination";
import { useEventList } from "@/hooks/list/use-event-list";

const ProfileEvents = () => {
  const { data, isLoading, page, setPage, totalCount, pagesNum } =
    useEventList();

  return (
    <div className="mt-6 -mx-10">
      <EventList total={totalCount} data={data} isLoading={isLoading} />
      <Pagination total={pagesNum} onChange={setPage} current={page} />
    </div>
  );
};

export default ProfileEvents;
