import { Button } from "@/ui-kit/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/ui-kit/dialog";
import { Input } from "@/components/rhf/input";
import { LockIcon } from "@/icons/lock";

export const AthleteChangePassword = () => {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="link">Изменить пароль</Button>
      </DialogTrigger>
      <DialogContent className="px-14 pt-20 pb-10 max-w-[32rem]">
        <DialogHeader>
          <DialogTitle className="h5 text-center">Изменить пароль</DialogTitle>
          <DialogClose />
        </DialogHeader>
        {/* TODO For now it's ok, but when there will be BE need to extract to form component */}
        <Input
          withVisibilityToggle
          name="password"
          controlElementClassName="mt-4"
          labelClassName="font-bold"
          placeholder="Старый пароль"
          startAdornment={<LockIcon className="text-primary" />}
        />
        <Input
          withVisibilityToggle
          name="password"
          controlElementClassName="mt-4"
          labelClassName="font-bold"
          placeholder="Новый пароль"
          startAdornment={<LockIcon className="text-primary" />}
        />
        <DialogFooter className="flex gap-2 md:justify-center">
          <DialogClose asChild>
            <Button>Отправить</Button>
          </DialogClose>
          <DialogClose asChild>
            <Button variant="outlineMain">Отмена</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
