import { meState } from "@/atoms/account/me";
import { AthleteSummary } from "@/components/athlete-summary";
import { AthleteDetailCard } from "@/types/athlete";
import { useRecoilValue } from "recoil";

const ProfileMain = () => {
  const me = useRecoilValue(meState);

  return <AthleteSummary isOwner data={me as AthleteDetailCard} />;
};

export default ProfileMain;
