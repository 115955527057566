import { Button } from "@/ui-kit/button";
import { Form, FormMessage } from "@/ui-kit/form";
import { Input } from "@/components/rhf/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { athleteSchema } from "@/schemas/athlete.schema";
import { Athlete, AthleteDetailCard } from "@/types/athlete";
import { useRecoilValue } from "recoil";
import { sportDictionaryState } from "@/atoms/sport/sport-dictionary";
import { Dropdown } from "@/components/dropdown";
import { FC } from "react";
import { LocationInput } from "@/components/rhf/location-input";
import { LocationIcon } from "@/icons/location";
import { useAthlete } from "@/hooks/api/use-athlete";

type EditProfileAboutFormProps = {
  defaultValues: AthleteDetailCard | undefined;
};

export const EditAboutProfileForm: FC<EditProfileAboutFormProps> = ({
  defaultValues,
}) => {
  const { updateAthlete } = useAthlete();

  const form = useForm<Athlete>({
    resolver: zodResolver(athleteSchema),
    defaultValues: {
      ...defaultValues,
      location: defaultValues?.location.position,
    },
  });
  const sportDictionary = useRecoilValue(sportDictionaryState);

  const onSubmit = async (values: Athlete) => {
    try {
      updateAthlete({
        ...defaultValues,
        ...values,
        location: {
          latitude: values.location?.latitude || 0,
          longitude: values.location?.longitude || 0,
        },
      });
    } catch (error) {
      console.log(error);
      form.setError("root", { message: "Произошла ошибка" });
    }
  };

  console.log("sportDictionary: ", sportDictionary);

  return (
    <Form {...form}>
      <form className="flex flex-col" onSubmit={form.handleSubmit(onSubmit)}>
        <Dropdown
          name="sports"
          isMulti
          placeholder="Вид спорта (добавить еще)"
          onChange={(selected) => {
            form.setValue(
              "sportIds",
              selected.map((option) => option.value),
            );
          }}
          className="mt-2"
          options={Array.from(sportDictionary.values())}
        />
        <LocationInput
          name="location"
          controlElementClassName="mt-2"
          placeholder="Локация/Место"
          startAdornment={<LocationIcon className="w-4 h-4 !top-2" />}
        />
        <Input
          name="description"
          controlElementClassName="mt-2"
          labelClassName="font-bold"
          placeholder="О себе"
        />
        {form.formState.errors.root && (
          <FormMessage className="self-center">
            {form.formState.errors.root.message}
          </FormMessage>
        )}
        <div className="flex justify-center gap-2.5 py-4">
          <Button type="submit">Сохранить</Button>
          <Button variant="outlineMain" type="reset">
            Отмена
          </Button>
        </div>
      </form>
    </Form>
  );
};
