import {
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationEllipsis,
  PaginationNext,
  Pagination as UIKitPagination,
  PaginationPrevious,
} from "@/ui-kit/pagination";
import { FC } from "react";

type PaginationProps = {
  total: number;
  current: number;
  onChange: (page: number) => void;
};

export const Pagination: FC<PaginationProps> = ({
  total,
  current,
  onChange,
}) => {
  // genious shit provided by gpt, looks a little bit ugly but works as expected =/
  const paginationBody = (() => {
    const pageNumbers = [];

    if (current > 1) {
      pageNumbers.push(1);
    }

    if (current > 2) {
      pageNumbers.push("...");
    }

    if (current >= 1 && current <= total) {
      pageNumbers.push(current);
    }

    if (current + 1 <= total) {
      pageNumbers.push(current + 1);
    }

    if (current + 2 < total) {
      pageNumbers.push("...");
      pageNumbers.push(total);
    }

    return pageNumbers.map((number, index) => (
      <PaginationItem key={index}>
        {number === "..." ? (
          <PaginationEllipsis>...</PaginationEllipsis>
        ) : (
          <PaginationLink
            isActive={number === current}
            onClick={() => onChange(number as number)}
            className="hover:mx-0"
          >
            {number}
          </PaginationLink>
        )}
      </PaginationItem>
    ));
  })();

  return (
    <UIKitPagination className="my-4">
      <PaginationContent>
        <PaginationItem>
          {current > 1 ? (
            <PaginationPrevious
              size="md"
              onClick={() => onChange(current - 1)}
            />
          ) : (
            <div className="w-16"></div>
          )}
        </PaginationItem>
        {paginationBody}
        <PaginationItem>
          {current < total ? (
            <PaginationNext size="md" onClick={() => onChange(current + 1)} />
          ) : (
            <div className="w-16"></div>
          )}
        </PaginationItem>
      </PaginationContent>
    </UIKitPagination>
  );
};
