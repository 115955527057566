import { createBrowserRouter } from "react-router-dom";
import {
  Athlete,
  Athletes,
  ErrorPage,
  EventCreate,
  EventEdit,
  Events,
  Event,
  Login,
  Main,
  ProfileEditLayout,
  ProfileEditMain,
  ProfileEditAbout,
  ProfileEditRole,
  ProfileEditPrivacy,
  ProfileEvents,
  ProfileLayout,
  ProfileMain,
  ProfileOther,
  ProfileTeam,
  Register,
  Root,
} from "./pages";
import ProtectedRoute from "./protected-route";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Main />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/register",
        element: <Register />,
      },
      {
        path: "/profile",
        element: (
          <ProtectedRoute>
            <ProfileLayout />
          </ProtectedRoute>
        ),
        children: [
          {
            path: "",
            element: <ProfileMain />,
          },
          {
            path: "events",
            element: <ProfileEvents />,
          },
          {
            path: "team",
            element: <ProfileTeam />,
          },
          {
            path: "other",
            element: <ProfileOther />,
          },
        ],
      },
      {
        path: "/profile/edit",
        element: (
          <ProtectedRoute>
            <ProfileEditLayout />
          </ProtectedRoute>
        ),
        children: [
          {
            path: "",
            element: <ProfileEditMain />,
          },
          {
            path: "about",
            element: <ProfileEditAbout />,
          },
          {
            path: "role",
            element: <ProfileEditRole />,
          },
          {
            path: "privacy",
            element: <ProfileEditPrivacy />,
          },
        ],
      },
      {
        path: "/athletes",
        element: <Athletes />,
      },
      {
        path: "/athletes/:id",
        element: <Athlete />,
      },
      {
        path: "/events",
        element: <Events />,
      },
      {
        path: "/events/create",
        element: (
          <ProtectedRoute>
            <EventCreate />
          </ProtectedRoute>
        ),
      },
      {
        path: "/events/:id",
        element: <Event />,
      },
      {
        path: "/events/:id/edit",
        element: (
          <ProtectedRoute>
            <EventEdit />
          </ProtectedRoute>
        ),
      },
    ],
  },
]);
