import { Outlet } from "react-router-dom";
import { NavigationPaths } from "@/components/navigation-paths";
import { useMe } from "@/hooks/use-me";

const PATHS = [
  { title: "Профиль", path: "/profile" },
  { title: "События", path: "/profile/events" },
  { title: "Моя команда", path: "/profile/team" },
  { title: "Другое", path: "/profile/other" },
];

const ProfileLayout = () => {
  useMe();

  return (
    <div className="bg-white w-full min-h-full">
      <div className="m-auto flex flex-col my-12 content-padding-64">
        <NavigationPaths list={PATHS} />
        <Outlet />
      </div>
    </div>
  );
};

export default ProfileLayout;
