import { FC, useMemo } from "react";
import { Skeleton } from "@/ui-kit/skeleton";
import { AthleteCard } from "@/components/card/athlete-card";
import { AthleteSummaryCard } from "@/types/athlete";

type AthletesListProps = {
  total?: number;
  data: AthleteSummaryCard[];
  isLoading: boolean;
};

export const AthletesList: FC<AthletesListProps> = ({
  total,
  data,
  isLoading,
}) => {
  const athletes = useMemo(
    () =>
      isLoading
        ? Array.from({ length: 8 }).map((_, index) => (
            <Skeleton
              key={index}
              className="min-w-48 w-screen max-w-[282px] min-h-96 h-screen max-h-[500px] rounded-2xl"
            />
          ))
        : data.map((athlete, index) => (
            <AthleteCard
              key={index}
              {...athlete}
              location={athlete.location.name || ""}
              buttonText="Подробнее"
            />
          )),
    [data, isLoading],
  );

  return (
    <div className="w-full">
      <div className="flex justify-between">
        <div className="flex gap-4 items-end">
          <h4 className="h4">Спортсмены</h4>
          {total && (
            <span className="ct text-gray-100 mb-[0.3rem]">
              Найдено: {total}
            </span>
          )}
        </div>
        <div>Фильтр</div>
      </div>
      <div className="flex flex-wrap gap-2 justify-center md:justify-start my-8 min-h-96">
        {athletes}
      </div>
    </div>
  );
};
