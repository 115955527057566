import { useAthlete } from "@/hooks/api/use-athlete";
import { useOrganization } from "@/hooks/api/use-organization";
import { useRecoilState, useRecoilValue } from "recoil";
import { useEffect, useMemo, useState } from "react";
import { GetAvatarCategoryEnum, Organization } from "@/api/generated";
import { accountState } from "@/atoms/account/account";
import { AccountRole } from "@/types/account-role";
import { useAvatar } from "@/hooks/api/use-avatar";
import { AthleteDetailCard } from "@/types/athlete";
import { meState } from "@/atoms/account/me";

const abbr = (value: string | undefined) => value?.at(0)?.toUpperCase() ?? "";

export const useMe = () => {
  const account = useRecoilValue(accountState);
  const [me, setMeState] = useRecoilState(meState);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { athleteMe } = useAthlete();
  const { organizationMe } = useOrganization();
  const { byCategoryAndIdUrl } = useAvatar();

  console.log("account: ", account);

  const fetchData = async () => {
    if (account.id) {
      // TODO think about utilizing react query for loading state, cache invalidation and so on, since this looks ugly
      setIsLoading(() => true);
      if (account.role === AccountRole.ORGANIZER) {
        const organization = await organizationMe();
        setMeState({
          ...organization,
          name: organization?.name || "",
          description: organization?.description || "",
          location: organization?.location.position || {
            latitude: 0,
            longitude: 0,
          },
          sportIds: [],
        });
      } else {
        const athlete = await athleteMe();
        if (athlete) {
          const avatarUrl = await byCategoryAndIdUrl(
            GetAvatarCategoryEnum.Account,
            athlete.id,
          );
          setMeState({
            ...athlete,
            avatar: avatarUrl,
            rating: 4,
            achievements: ["Bronze", "Silver", "Gold", "Regular"],
          });
        }
      }
      setIsLoading(() => false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [account.id, account.role]);

  const abbriviation = useMemo(() => {
    if (account.role === AccountRole.ORGANIZER) {
      return abbr((me as unknown as Organization)?.name);
    } else {
      return (
        abbr((me as AthleteDetailCard)?.firstName) +
        abbr((me as AthleteDetailCard)?.lastName)
      );
    }
  }, [account.role, me]);

  return {
    abbriviation,
    isLoading,
  };
};
