import { Link } from "react-router-dom";

import { PlusIcon } from "@/icons/plus";
import { LogoImage } from "@/icons/logo";
import { Button } from "@/ui-kit/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/ui-kit/tooltip";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/ui-kit/dropdown";
import { useAuth } from "@/hooks/use-auth";
import { useMe } from "@/hooks/use-me";
import { Avatar, AvatarFallback, AvatarImage } from "@/ui-kit/avatar";
import { AthleteDetailCard } from "@/types/athlete";
import { useRecoilValue } from "recoil";
import { meState } from "@/atoms/account/me";

export const Header = () => {
  const { logout } = useAuth();
  const { abbriviation } = useMe();
  const me = useRecoilValue(meState);

  return (
    <header className="content-padding-64 py-6 flex justify-between items-center flex-wrap shadow-header z-10">
      <Link to="/">
        <LogoImage />
      </Link>
      <div className="flex gap-4">
        <Button variant="ghost" size="sm" asChild>
          <Link to="/events">События</Link>
        </Button>
        <Button variant="ghost" size="sm" asChild>
          <Link to="/athletes">Люди</Link>
        </Button>
        <Button variant="outline" size="sm">
          Расскажи о нас
        </Button>
      </div>
      <div className="flex gap-4">
        {!!me ? (
          <>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button variant={"ghost"} size={"icon"} asChild>
                    <Link to="/events/create">
                      <PlusIcon />
                    </Link>
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Добавить событие</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Avatar className="cursor-pointer">
                  <AvatarImage src={(me as AthleteDetailCard)?.avatar} />
                  <AvatarFallback>{abbriviation}</AvatarFallback>
                </Avatar>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-56">
                <DropdownMenuItem asChild>
                  <Link to="/profile" className="cursor-pointer">
                    <span>Мой профиль</span>
                  </Link>
                </DropdownMenuItem>
                <DropdownMenuItem asChild className="cursor-pointer">
                  <Link to="/" onClick={logout}>
                    <span>Выйти</span>
                  </Link>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </>
        ) : (
          <Button variant="outline" size="sm" asChild>
            <Link to="/login">Войти</Link>
          </Button>
        )}
        <Button variant="ghost" size="sm">
          EN
        </Button>
      </div>
    </header>
  );
};
