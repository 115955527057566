import { Button } from "@/ui-kit/button";
import { cn } from "@/utils/tailwind";
import { FC } from "react";
import { Link, useLocation } from "react-router-dom";

type NavigationLinksProps = {
  list: { title: string; path: string }[];
};

export const NavigationPaths: FC<NavigationLinksProps> = ({ list }) => {
  const location = useLocation();

  return (
    <div className="flex gap-2.5 flex-wrap justify-center md:justify-none">
      {list.map(({ title, path }) => {
        const isActive = location.pathname === path;
        return (
          <Button
            key={path}
            className={cn("flex-1 min-w-60", {
              "bg-primary text-white": isActive,
            })}
            disabled={isActive}
            asChild={!isActive}
            variant="outline"
            size="lg"
          >
            <Link to={path}>{title}</Link>
          </Button>
        );
      })}
    </div>
  );
};
