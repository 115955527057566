import { FC, useMemo } from "react";
import { EventCard } from "@/components/card/event-card";
import { Skeleton } from "@/ui-kit/skeleton";
import { Select } from "@/components/select";
import { EventSummaryCard } from "@/types/event";

type EventListProps = {
  total?: number;
  data: EventSummaryCard[];
  isLoading: boolean;
};

export const EventList: FC<EventListProps> = ({ total, data, isLoading }) => {
  const events = useMemo(
    () =>
      isLoading
        ? Array.from({ length: 8 }).map((_, index) => (
            <Skeleton
              key={index}
              className="min-w-48 w-screen max-w-[282px] min-h-96 h-screen max-h-[500px] rounded-2xl"
            />
          ))
        : data.map((event, index) => (
            <EventCard key={index} {...event} buttonText="Подробнее" />
          )),
    [data, isLoading],
  );

  return (
    <div className="w-full">
      <div className="flex justify-between">
        <div className="flex gap-4 items-end">
          <h4 className="h4">События</h4>
          {total && (
            <span className="ct text-gray-100 mb-[0.3rem]">
              Найдено: {total}
            </span>
          )}
        </div>
        <Select
          placeholder="Фильтр"
          onValueChange={(selected) => {
            console.log("filter: ", selected);
          }}
          defaultValue=""
          className="mt-2"
          options={[{ value: "all", label: "Все" }]}
        />
      </div>
      <div className="flex flex-wrap gap-2 justify-center lg:justify-between my-8 min-h-96">
        {events}
      </div>
    </div>
  );
};
