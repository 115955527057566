import { CloseIconSmall } from "@/icons/close-small";
import { Button } from "@/ui-kit/button";
import { cn } from "@/utils/tailwind";
import StateManagedSelect from "react-select";
import Select from "react-select";

type DropdownProps = StateManagedSelect;

// TODO find proper typing for this one.
const CustomClearIndicator = ({ innerProps }: any) => (
  <div {...innerProps}>
    <Button variant="ghost" size="icon">
      <CloseIconSmall />
    </Button>
  </div>
);

export const Dropdown: DropdownProps = ({
  name,
  isMulti,
  placeholder,
  onChange,
  options,
  className,
  isClearable = true,
}) => {
  return (
    <Select
      isClearable={isClearable}
      name={name}
      isMulti={isMulti}
      classNames={{
        control: () => "pl-5 !rounded-xl !border-blue-200 text-l !min-h-10",
        placeholder: () => "ml-2 ft1",
        option: ({ data, isDisabled, isFocused, isSelected }) =>
          cn("text-left pl-8 ft1"),
        valueContainer: () => "pl-2 text-left",
        multiValue: () => "-ml-2 ft1 bg-transparent",
        multiValueLabel: () => "ml-2 ft1 bg-transparent",
        multiValueRemove: () => "ml-2 ft1 bg-transparent",
        singleValue: () => "ml-2 ft1 bg-transparent",
      }}
      placeholder={placeholder}
      onChange={onChange}
      options={options}
      className={className}
      components={{ ClearIndicator: CustomClearIndicator }}
    />
  );
};
