import { EventControllerApi, EventSummary, PaginatedResultEventSummary } from "@/api/generated";
import { EMPTY_PAGINATED_LIST } from '@/consts/list';
import { useHttpClient } from "@/hooks/use-http-client";
import {
  Event,
  EventSearchParams,
  ExternalEvent,
  NewEvent,
  NewExternalEvent,
} from "@/types/event";
import { SortablePageRequestParams } from "@/types/page";

export const useEvent = () => {
  const httpClient = useHttpClient();

  const controller = new EventControllerApi(undefined, undefined, httpClient);

  const createEvent = async (event: NewEvent) => {
    try {
      const response = await controller.createEvent({
        ...event,
      });
      return response.data;
    } catch (error) {
      console.log("Error during createEvent api call: ", error);
      throw error;
    }
  };
  const createExternalEvent = async (event: NewExternalEvent) => {
    try {
      const response = await controller.createExternalEvent({
        ...event,
      });
      return response.data;
    } catch (error) {
      console.log("Error during createExternalEvent api call: ", error);
      throw error;
    }
  };

  const updateEvent = async (event: Event) => {
    try {
      const response = await controller.updateEvent(event.id, {
        ...event,
      });

      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  const updateExternalEvent = async (event: ExternalEvent) => {
    try {
      const response = await controller.updateExternalEvent(event.id, {
        ...event,
      });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  const getEvent = async (eventId: number) => {
    try {
      const response = await controller.getEvent(eventId);

      return response.data;
    } catch (error) {
      console.log(error);
    }
  };
  const getEvents = async (
    params: SortablePageRequestParams<EventSearchParams>,
  ): Promise<PaginatedResultEventSummary> => {
    try {
      const {
        page,
        size,
        sort,
        searchParams: {
          name,
          location,
          sportId,
          date,
          distance,
        },
      } = params;
      const response = await controller.searchEvents(
        location?.latitude || 0,
        location?.longitude || 0,
        page,
        size,
        sort,
        name,
        sportId,
        date,
        distance,
      );
      return response.data;
    } catch (error) {
      return EMPTY_PAGINATED_LIST;
    }
  };

  return {
    createEvent,
    createExternalEvent,
    getEvent,
    getEvents,
    updateEvent,
    updateExternalEvent,
  };
};
