import { AthleteSummary } from "@/components/athlete-summary";
import { useAthleteData } from "@/hooks/use-athlete-data";
import { useParams } from "react-router-dom";

const Athlete = () => {
  const { id } = useParams();

  const { data, isLoading } = useAthleteData(id!);

  return (
    <div className="bg-white w-full content-padding-64">
      <AthleteSummary data={data} isLoading={isLoading} />
    </div>
  );
};

export default Athlete;
