import { Card, CardContent, CardHeader, CardTitle } from "@/ui-kit/card";
import { useSportDictionary } from "@/hooks/use-sport-dictionary";
import { EditAboutProfileForm } from "@/components/forms/edit-about-profile-form";
import { meState } from "@/atoms/account/me";
import { useRecoilValue } from "recoil";
import { AthleteDetailCard } from "@/types/athlete";

export const AboutProfileEditCard = () => {
  const me = useRecoilValue(meState);
  useSportDictionary();

  return (
    <Card className="min-w-[300px] w-screen max-w-[500px] my-8 mx-auto rounded-2xl">
      <CardHeader>
        <CardTitle>Редактировать профиль</CardTitle>
      </CardHeader>
      <CardContent>
        {!me ? (
          <div>Данные не загружены</div>
        ) : (
          <EditAboutProfileForm defaultValues={me as AthleteDetailCard} />
        )}
      </CardContent>
    </Card>
  );
};
