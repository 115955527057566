import { SearchWithFiltersCard } from "@/components/card/search-with-filters-card";
import { AthletesList } from "@/components/list/athletes-list";
import { Pagination } from "@/components/pagination";
import { useAthletesList } from "@/hooks/list/use-athletes-list";

const Athletes = () => {
  const { data, isLoading, page, setPage, totalCount, pagesNum } =
    useAthletesList();

  return (
    <div className="bg-white w-full">
      <div className="content-padding-64 mt-12">
        <SearchWithFiltersCard />
      </div>
      <div className="content-padding-64 md:content-padding-56 mt-12">
        <AthletesList total={totalCount} data={data} isLoading={isLoading} />
        {pagesNum > 1 && (
          <Pagination total={pagesNum} onChange={setPage} current={page} />
        )}
      </div>
    </div>
  );
};

export default Athletes;
