import { Outlet } from "react-router-dom";
import { NavigationPaths } from "@/components/navigation-paths";

const PATHS = [
  {
    path: "/profile/edit",
    title: "Основное",
  },
  {
    path: "/profile/edit/about",
    title: "О себе",
  },
  {
    path: "/profile/edit/role",
    title: "Тип пользователя",
  },
  {
    path: "/profile/edit/privacy",
    title: "Конфиденциальность",
  },
];

const ProfileEditLayout = () => {
  return (
    <div className="w-full">
      <div className="m-auto flex flex-col my-12 content-padding-64">
        <NavigationPaths list={PATHS} />
        <Outlet />
      </div>
    </div>
  );
};

export default ProfileEditLayout;
